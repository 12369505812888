/* eslint jsx-a11y/control-has-associated-label: "off", curly: "error" */

import React, { Fragment } from "react"
import { Row, Col } from "react-bootstrap"
import CommonFields from "./Inputs/CommonFields"
import CurrentPage from "./Inputs/CurrentPage"
import NetlifyForm from "./Template/NetlifyForm"

const CallBackForm = ({ showText }) => (
  <Fragment>
    {showText && (
      <Row>
        <Col className="text-center mx-auto" md={10}>
          <h2>Call Back Request</h2>
          <p>Enter your details to request a FREE call back.</p>
          <p>Please complete all fields</p>
        </Col>
      </Row>
    )}

    <NetlifyForm name="contact" btnText="Call me back">
      <CommonFields />
      <div className="form-group">
        <label className="sr-only" htmlFor="callTime">
          Call back time
        </label>
        <select className="form-control" name="callTime" required>
          <option value="1 hour" selected>
            1 hour
          </option>
          <option value="2 hours">2 hours</option>
          <option value="3 hours">3 hours</option>
          <option value="4 hours">4 hours</option>
          <option value="5 hours">5 hours</option>
          <option value="6 hours">6 hours</option>
        </select>
      </div>

      <CurrentPage />
    </NetlifyForm>

    <div className="text-center small">
      <p>Call us on (+44) 203 709 4275</p>

      <p>
        By completing this form, you give us permission to send your contact
        information to the property developer or their appointed agent.
      </p>

      <p>
        They are best positioned to answer your queries and will call you back
        to discuss your requirements and send you relevant information.
      </p>
    </div>
  </Fragment>
)

export default CallBackForm
