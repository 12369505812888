/* eslint jsx-a11y/control-has-associated-label: "off", curly: "error" */

import React, { Fragment } from "react"
import { Link } from "gatsby"

import CommonFields from "./Inputs/CommonFields"
import CurrentPage from "./Inputs/CurrentPage"
import MailingLists from "./Inputs/MailingLists"
import NetlifyForm from "./Template/NetlifyForm"

const financesAvailable = [
  "Cash funds available right now",
  "Waiting on the sale of a house",
  "Needs to sell shares first",
  "Will need to remortgage before going ahead",
  "Have 25% for deposit but will need a mortgage for balance",
  "Have been approved for a mortgage, just need to find a suitable property",
]

const purchaseTimelines = [
  "Within the next month",
  "Within the next 3 months",
  "Within the next 6 months",
]

const otherInterests = [
  "Care Homes Investments",
  "Buy-to-let U.K Residential Property",
  "Student Property Investments",
  "Hotel Room Investment",
  "Any High Yield Investment",
  "HMO",
  "JV on Developments",
  "Freehold Houses Only",
  "Commercial Property Blocks",
]

const GetPropertyDetailsForm = () => (
  <Fragment>
    <div className="text-center">
      <h2>Enquire about this property</h2>
      <p>
        By completing this form, you give us permission to send your contact
        information to the property developer or their appointed agent.
      </p>
      <p>
        They will share the property brochure and relevant information to help
        you make an informed decision. You hereby agree that your details will
        be added to a mailing list to receive emails of similar opportunities.
      </p>
    </div>

    <NetlifyForm name="property-details" btnText="Enquire Now">
      <CommonFields />

      <div className="form-group">
        <label className="w-100" htmlFor="propertyRequirements">
          <span className="sr-only">Property Requirements</span>
          <textarea
            className="form-control"
            name="propertyRequirements"
            placeholder="Top 5 property requirements"
            type="text"
            rows="5"
          />
        </label>
      </div>

      <div className="form-group">
        <label htmlFor="finances">Finances Available:</label>
        <select defaultValue="Default" className="form-control" name="finances">
          <option value="Default" disabled>
            Drop Down Menu
          </option>
          {financesAvailable.map((val, i) => (
            <option key={i} value={val}>
              {val}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="pruchaseTimeline">Purchase Timeline:</label>
        <select
          defaultValue="Default"
          className="form-control"
          name="pruchaseTimeline"
        >
          <option value="Default" disabled>
            Drop Down Menu
          </option>
          {purchaseTimelines.map((val, i) => (
            <option key={i} value={val}>
              {val}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <fieldset>
          <p>I am also interested in:</p>
          {otherInterests.map((val, i) => (
            <div key={i} className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={val}
                  name="otherInterests[]"
                />

                {val}
              </label>
            </div>
          ))}
        </fieldset>
      </div>

      <MailingLists />

      <CurrentPage />
    </NetlifyForm>

    <div className="text-center small">
      <p>
        Your details and preferences will only be shared with the specific
        developer / agent of the property you enquired about.
      </p>

      <Link to="/privacy-policy">Privacy Policy</Link>
    </div>
  </Fragment>
)

export default GetPropertyDetailsForm
