import React, { useContext } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { CurveCarveTop, CurveCarveBottom } from "./Curves/Curve"
import Logo from "../images/Logo-Text.svg"
import { mediaBreakpoint } from "../utils/breakpoints"

import google from "../images/accreditations/google.png"
import reviews from "../images/accreditations/reviews.png"
import { useHubPages } from "../hooks/useHubPages"
import styled from "styled-components"
import { SqueezePageCTX } from "../templates/squeeze-pages/SqueezePageContext"

const StyledFooter = styled.footer`
  a {
    color: ${({ theme }) => theme.primary};
  }

  @media ${mediaBreakpoint.down.md} {
    .list-unstyled {
      padding-left: 1rem;
      line-height: 3rem;
    }
  }

  .image-column {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    img {
      object-fit: contain;
      padding: 15px;
    }
  }

  .dont-break-out {
    /* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
`

const Footer = () => {
  const { areas, sectors } = useHubPages()
  const squeezePageCtx = useContext(SqueezePageCTX)

  return (
    <StyledFooter>
      {squeezePageCtx ? (
        <CurveCarveTop variant="light" />
      ) : (
        <>
          <CurveCarveTop variant="primary" />
          <div className="bg-primary py-5 text-white">
            <Container>
              <Row>
                <Col md={3}>
                  <Link to="/">
                    <img
                      src={Logo}
                      alt="One Touch Property Investment Logo"
                      width="180"
                      height="50"
                      className="mb-5"
                    />
                  </Link>
                </Col>
                <Col md={3}>
                  <div className="h5">Sectors</div>
                  <ul className="list-unstyled">
                    {sectors.map(sector => (
                      <li key={sector.id}>
                        <Link to={sector.permalink} className="text-light">
                          {sector.subtitle}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Col>
                <Col md={3}>
                  <div className="h5">Useful Links</div>
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        to="/property-investment-company"
                        className="text-light"
                      >
                        About One Touch
                      </Link>
                    </li>
                    <li>
                      <Link to="/property-sourcing" className="text-light">
                        Property Sourcing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/property-investment-due-diligence"
                        className="text-light"
                      >
                        Investment Due Diligence
                      </Link>
                    </li>
                    <li>
                      <Link to="/jv-on-uk-developments" className="text-light">
                        JV on UK Developments
                      </Link>
                    </li>
                    <li>
                      <Link to="/testimonials" className="text-light">
                        Testimonials
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy" className="text-light">
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-of-business" className="text-light">
                        Terms of Business
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col md={3}>
                  <div className="h5">Locations</div>
                  <ul className="list-unstyled">
                    {areas.map(area => (
                      <li key={area.id}>
                        <Link to={area.permalink} className="text-light">
                          {area.subtitle}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>
          <CurveCarveBottom variant="primary" background="light" />
        </>
      )}

      <div className="bg-light">
        <Container>
          <Row>
            <Col className="image-column">
              <img
                src={google}
                alt="Google Reviews - 5 Stars"
                width="200"
                height="100"
              />
            </Col>

            <Col className="image-column">
              <img
                src={reviews}
                alt="Reivews.io - 4.5 Stars"
                width="200"
                height="100"
              />
            </Col>
          </Row>

          <br />

          <Row>
            <Col>
              {squeezePageCtx ? (
                <p className="text-center">124 City Road, London, EC1V 2NX</p>
              ) : (
                <p className="text-center dont-break-out">
                  Tel:{" "}
                  <a
                    href="tel:+44 203 709 4275"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +44 (0) 203 709 4275
                  </a>{" "}
                  | Email:{" "}
                  <a
                    href="mailto: enquire@onetouchinvestment.co.uk"
                    target="_blank"
                    rel="noreferrer"
                  >
                    enquire@onetouchinvestment.co.uk
                  </a>{" "}
                  | 124 City Road, London, EC1V 2NX
                </p>
              )}

              <p className="text-center small">
                Please note that One Touch Property is not authorised or
                regulated by the Financial Conduct Authority and as such is not
                permitted to offer financial or investment advice to UK resident
                investors, whether or not the intended investments are regulated
                or unregulated. We strongly encourage you to consult an
                FCA-authorised Independent Financial Adviser before committing
                to any form of investment. This is not an offer to participate
                in a collective investment scheme as defined in section 235 of
                the Financial Services and Markets Act (2000) and as such
                investors will not have access to the Financial Services
                Compensation Scheme or the Financial Ombudsman Service.
              </p>
            </Col>
          </Row>

          <Row>
            <Col>
              <p className="text-center small">
                Copyright © {new Date().getFullYear()}. All Rights Reserved by
                One Brand Management Limited
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </StyledFooter>
  )
}

export default Footer
